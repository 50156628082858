<template>
  <div>
    <div
      v-if="loading"
      class="text-sm italic text-gray-500 font-medium h-10 w-full bg-gray-200 flex items-center justify-start px-3 rounded"
    >
      <icon icon="mdi:loading" class="animate-spin mr-2" />
      Cargando productos
    </div>
    <div v-else>
      <VueMultiselect
      v-model="selected"
      track-by="id"
      label="name"
      :multiple="true"
      :options="products"
      :close-on-select="false"
      />
      <input
      type="hidden"
      :value="selectedProductsId"
      name="related_products"
      >
    </div>
  </div>
</template>
<script>

import VueMultiselect from 'vue-multiselect';

import productsApi from '@/api/store/products';
import { Icon } from '@iconify/vue';


export default {
  components: { VueMultiselect, Icon },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      selected: [],
      products: [],
      loading: true,
    };
  },
  async mounted () {
    this.products = (await productsApi.index()).data.data;
    this.selected = this.products?.filter(product => this.value.includes(product.id));

    this.loading = false;
  },
  computed: {
    selectedProductsId () {
      return JSON.stringify(this.selected?.map(product => product.id));
    },
  },
};

</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
