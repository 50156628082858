<template>
  <div class="w-full">
    <div class="flex flex-col items-center sm:hidden w-full border-2 py-2 px-3 border-custom-900">
      <div
        class="flex justify-between w-full items-center"
        @click="showMobileDropdown = !showMobileDropdown"
      >
        <div>
          Categorias
        </div>
        <v-icon
          icon="mdi-chevron-down"
          class="text-3xl"
          @click="toggleList(tag.id)"
        />
      </div>
      <div
        v-if="showMobileDropdown"
        class="w-full px-5 py-2"
      >
        <a
          v-for="tag in tags"
          :key="tag.id"
          :href="`/tag/${tag.slug}`"
          class="flex flex-col py-1"
        >
          <div class="whitespace-nowrap">
            {{ tag.name }}
          </div>
        </a>
      </div>
    </div>
    <div
      class="sm:flex hidden flex-wrap content-between justify-between
    gap-y-5 gap-x-32 w-full py-10"
    >
      <div
        v-for="tag in tags"
        :key="tag.id"
        class="flex items-center gap-2 justify-start"
      >
        <div class="relative">
          <OnClickOutside @trigger="tagListOpen = null">
            <Transition mode="out-in">
              <div
                v-if="tagListOpen == tag.id"
                class="absolute top-0 mt-5
            bg-white text-black p-6 flex flex-col gap-7 shadow-0 border border-custom-800 shadow-custom-800 z-10"
              >
                <a
                  v-for="child in tag.children"
                  :key="child.id"
                  :href="`/tag/${child.slug}`"
                  class="whitespace-nowrap uppercase text-lg"
                >
                  {{ child.name }}
                </a>
              </div>
            </Transition>
          </OnClickOutside>
        </div>

        <a
          :href="`/tag/${tag.slug}`"
          class="whitespace-nowrap uppercase text-2xl"
        >
          {{ tag.name }}
        </a>
        <div v-if="tag?.children?.length">
          <v-icon
            v-if="tagListOpen == tag.id"
            icon="mdi-chevron-up"
            class="text-3xl"
          />
          <v-icon
            v-else
            icon="mdi-chevron-down"
            class="text-3xl"
            @click="toggleList(tag.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { OnClickOutside } from '@vueuse/components';

export default {
  components: {
    OnClickOutside,
  },
  props: {
    tags: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tagListOpen: null,
      showMobileDropdown: false,
    };
  },
  methods: {
    toggleList(id){
      if (this.tagListOpen == id) {
        this.tagListOpen = null;
      } else {
        this.tagListOpen = id;
      }
    },
  },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  @apply transition;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>

