<template>
  <div class="flex gap-4 flex-col-reverse md:flex-row md:w-full md:justify-start">
    <div
      v-if="imageList.length > 0"
      class="h-16 w-full md:w-28 flex flex-row justify-evenly gap-2 md:gap-4 md:h-full md:min-w-28 md:flex-col md:items-center md:justify-evenly"
    >
      <div v-if="showArrows" @click="enableUpArrow ? scrollIndex = scrollIndex - 1 : null" :class="`text-black flex justify-center items-center text-3xl cursor-pointer select-none ${enableUpArrow ? 'text-opacity-100'  : 'text-opacity-20'}`">
        <div class="md:block hidden">
          <v-icon icon="mdi:chevron-up" />
        </div>
        <div class="block md:hidden">
          <v-icon icon="mdi:chevron-left" />
        </div>
      </div>
      <img
        v-for="[i, image] in imageList.slice(scrollIndex, scrollIndex+4).entries()"
        :key="image.uuid"
        class="w-16 h-16 md:h-28 md:w-28 object-cover border  border-custom-100"
        :src="image.original_url"
        @click="imageIndex = scrollIndex + i"
      >
      <div
        v-if="imageList.length === 0"
        class="bg-gray-300"
      />
      <div v-if="showArrows" @click="enableDownArrow ? scrollIndex = scrollIndex + 1 : null" :class="`text-black flex justify-center items-center text-3xl cursor-pointer select-none ${enableDownArrow ? 'text-opacity-100'  : 'text-opacity-20'}`">
        <div class="md:block hidden">
          <v-icon icon="mdi:chevron-down" />
        </div>
        <div class="block md:hidden">
          <v-icon icon="mdi:chevron-right" />
        </div>
      </div>
    </div>
    <div
      v-if="imageList.length > 0"
      class="flex items-center justify-center w-full bg-black bg-opacity-5"
    >
      <img
        :src="imageList[imageIndex].original_url"
        class="w-full object-center object-contain h-[400px] md:h-[500px] lg:h-[400px] xl:h-[600px]"
      >
    </div>
    <div
      v-else
      class="bg-custom-100 w-full italic md:w-auto md:h-[500px] lg:h-[400px] xl:h-[600px] xl:w-full flex font-semibold items-center justify-center"
    >
      <p>
        Libro sin imagenes
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageIndex: 0,
      imageList: Object.values(this.images),
      scrollIndex: 0
    };
  },
  methods: {
  },
  computed: {
    showArrows() {
      return this.imageList.length > 4;
    },
    enableUpArrow() {
      return this.scrollIndex > 0
    },
    enableDownArrow() {
      return this.imageList.length > 4 && this.scrollIndex < this.imageList.length - 4;
    },
  },
};
</script>
